import {DrawerHeader, useStylesDrawerHeaders} from "./index";
import * as React from "react";
import {ZevsLogo, FlashWhite, RusGidroLogo, RusGidroLogoMini} from '../../image/index'
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAuth} from "../../auth/AuthProvider";

export const DrawerHeaders = ({open}) => {
  const max_height_900 = useMediaQuery('(max-height:900px)')
  const classes = useStylesDrawerHeaders({open, max_height_900});
  const {user} = useAuth()

  return (
    <DrawerHeader className={classes.drawerHeader}>
      {user.role === 'RUSHYDRO_OPERATOR' ?
        <>
          {open ?
            <img src={RusGidroLogoMini} alt="logo" className={classes.drawerHeaderRusLogo}/>
            :
            <img src={RusGidroLogo} alt="logo" className={classes.drawerHeaderRusLogoMini}/>
          }
        </>
        :
        <>
          <img src={ZevsLogo} alt="logo" className={classes.drawerHeaderImgLogo}/>
          <img src={FlashWhite} alt="flash" className={classes.drawerHeaderImgFlash}/>
        </>
      }

    </DrawerHeader>
  )
}
