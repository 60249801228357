import {
  CurrencyExchangeOutlined,
  LogoutOutlined,
  FlashOnOutlined,
  BarChartOutlined,
  CompareArrowsOutlined,
  MenuBookOutlined,
  SettingsOutlined,
  DomainOutlined,
  PaymentOutlined,
  PersonOutline,
  LocalGroceryStoreOutlined,
  CalendarMonthOutlined,
  BadgeOutlined,
  SupervisedUserCircleOutlined
} from "@mui/icons-material";

export function navigation(t){

  return [
      {
        name: t('dashboard'),
        url: "/dashboard",
        icon: <BarChartOutlined sx={{fill: 'white'}}/>,
        select: true,
        permission: 'VIEW_CHARGING_STATION'
      },
      {
        name: t('payments'),
        url: "/payments",
        icon: <PaymentOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_PAYMENT'
      },
      {
        name: t('charging-stations-header'),
        url: "/charging-stations",
        icon: <FlashOnOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_CHARGING_STATION'
      },
      {
        name: t('customers-header'),
        url: "/customers",
        icon: <SupervisedUserCircleOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_CUSTOMER'
      },
      {
        name: t('tariff-plans'),
        url: "/tariffs",
        icon: <CurrencyExchangeOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_TARIFF'
      },
      {
        name: t('charging-sessions'),
        url: "/charging-sessions",
        icon: <CompareArrowsOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_CHARGING_SESSION'
      },
      {
        name: t('reservations-header'),
        url: "/reservations",
        icon: <CalendarMonthOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_RESERVATION'
      },
      {
        name: t('id-tokens-header'),
        url: "/id-tokens",
        icon: <BadgeOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_ID_TOKEN'
      },
      {
        name: t('orders-header'),
        url: "/orders",
        icon: <LocalGroceryStoreOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_ORDER'
      },
      {
        name: t('companies-header'),
        url: "/companies",
        icon: <DomainOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_COMPANY'
      },
      {
        name: t('employees-header'),
        url: "/employees",
        icon: <PersonOutline sx={{fill: 'white'}}/>,
        permission: 'VIEW_EMPLOYEE'
      },
      {
        name: t('reports-header'),
        url: "/reports",
        icon: <MenuBookOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_ORDER'
      },
      {
        name: t('settings'),
        url: "/settings",
        icon: <SettingsOutlined sx={{fill: 'white'}}/>,
        permission: 'VIEW_CHARGING_STATION'
      },
      // {
      //   name: t('exit'),
      //   url: null,
      //   icon: <LogoutOutlined sx={{fill: 'white'}}/>
      // }
    ]
}

