import {styled, makeStyles} from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";

export const useStylesDrawer = makeStyles(theme => ({
  drawerWrapper: props => ({
    height: '100vh',
    background: theme.palette.background.main,
    display: 'flex',
    alignContent: 'stretch',
    flexDirection: props.min_width_900 ? "row" : 'column',
    overflow: 'hidden',
  }),
  drawerIconButtonMobile: {
    margin: '13px 0px 0px 15px',
    padding: '1px 2px',
    border: '1px solid rgba(0,0,0,.3)',
    borderRadius: '5px'
  },
  drawerMenuIconButtonMobile: {
    fill: 'rgba(0,0,0,.3)',
    width: '40px !important',
    height: '30px !important'
  },
  drawerMenuIconLogoMobile: {
    width: '13rem !important',
    margin: '-10px -40px -15px -10px'
  },
}))
export const useStylesDrawerHeaders = makeStyles(theme => ({
  drawerHeader: {
    flexDirection: 'column',
    padding: '0 !important',
    margin: '0 !important',
    minHeight: '120px !important'
  },
  drawerHeaderRusLogoMini: props => ({
    width: '2.5rem',
    display: 'block',
    marginTop: '30%',
    marginBottom: '30%',
  }),
  drawerHeaderRusLogo: props => ({
    width: '9rem',
    display: 'block',
    marginTop: '30%',
    marginBottom: '30%',
  }),
  drawerHeaderImgLogo: props => ({
    display: 'block',
    marginTop: '8%',
    ...(!props.open && {
      transform: 'rotate(270deg)',
      marginBottom: '235%',
      marginTop: '70%'
    })
  }),
  drawerHeaderImgFlash: props => ({
    display: 'block',
    width: '25%',
    marginTop: '15%',
    marginBottom: '30%',
    ...((!props.open || props.max_height_900) && {
      display: 'none'
    })
  }),
}))
export const useStylesDrawerList = makeStyles(theme => ({
  drawerList: {
    flexGrow: '1',
    padding: '0 !important'
  },
  drawerListLink: {
    width: '100%',
    textDecoration: 'none'
  },
  drawerListItemButton: props => ({
    minHeight: 40,
    justifyContent: props.open ? 'initial' : 'center',
    '&.Mui-selected': {
      backgroundColor: "#1C87A9 !important"
    }
  }),
  drawerListItemIcon: props => ({
    minWidth: 0,
    justifyContent: 'center',
    marginRight: props.open ? "10px" : "0px",
  }),
  drawerListItemText: props => ({
    opacity: props.open ? 1 : 0,
    color: theme.palette.text.textColorMenu
  }),
  drawerListChevron: {
    paddingRight: '6px'
  },
  drawerListModal: {
    maxWidth: '300px !important'
  },
  drawerListModalButton: {
    marginRight: '10px'
  },
  drawerListMenuItemMobileExit: {
    color: theme.palette.text.textColorMenu,
  },
  drawerListMenuItemMobile: {
    padding: '10px 15px',
    color: theme.palette.text.textColorMenu,

    '&.Mui-selected': {
      backgroundColor: "#1C87A9 !important"
    }
  },
  drawerListItemIconMobile: {
    minWidth: '1px',
    width: '15px',
    margin: '0 !important',

  },
}))

export const WrapperButton = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  background: '#31353A'
}));
export const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignContent: 'stretch',
  overflow: 'hidden',

}));
export const MenuContainerMobile = styled('div')(({ theme }) => ({
  background: 'linear-gradient(90deg, #00b7f4, #6c6a67) !important',
  display: 'flex',
  justifyContent: 'space-between'
}));


function getInt(str) {
  return +str.replace(/[^0-9]/g, '')
}
const openedMixin = (theme) => {
  const drawerWidth = getInt(theme.components.MuiDrawer.styleOverrides.paper.width)
  return {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  }
};

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 2px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    const drawerWidth = getInt(theme.components.MuiDrawer.styleOverrides.paper.width)
    return {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      })
    }
  }
);
