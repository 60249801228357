import React, {Suspense, useEffect, useState} from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import cookies from 'js-cookie'
import './App.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {ToastContainer} from "react-toastify";
import {AuthProvider} from "./auth/AuthProvider";
import {ThemeProvider} from "@mui/material/styles";
import {mainTheme} from "./constants/theme";
import {MuiDrawer} from "./components/drawer";
import {routes} from "./routes";
import {useMediaQuery} from "@mui/material";

// import {ReactQueryDevtools} from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
})

const App = () => {
  const [language, setLanguage] = useState();
  const [theme, setTheme] = useState(cookies.get('theme') || true) // theme для светлая тёмная тема

  const media_max_width_900 = useMediaQuery('(max-width:900px)');
  const media_max_width_2000 = useMediaQuery('(min-width:2000px)');
  const media_max_height_900 = useMediaQuery('(max-height:900px)');
  const MUIMedia = {
    max_width_900: media_max_width_900,
    media_max_width_2000: media_max_width_2000,
    max_height_900: media_max_height_900,
    theme: theme
  }

  useEffect(()=>{
    const cookieTheme = cookies.get('theme')
    cookieTheme === 'false' ? setTheme(false) : setTheme(true)
  }, [])

  const loading = () => (
    <div className='appAuthLoader'>
      Авторизация...
    </div>
  )

  return (
    <>
      <ToastContainer className={'toastContainerStyled'}/>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={mainTheme(MUIMedia)}>
          <AuthProvider>

            <HashRouter>
              <MuiDrawer language={language}>
                <Suspense fallback={loading()}>
                  <Routes>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          caseSensitive={route.exact}
                          name={route.name}
                          element={<route.component setTheme={setTheme} theme={theme} setLanguage={setLanguage} {...route.props} />}
                        />
                      ) : null;
                    })}
                  </Routes>
                </Suspense>
              </MuiDrawer>
            </HashRouter>

          </AuthProvider>
        </ThemeProvider>
        {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
      </QueryClientProvider>
    </>
  )
}

export default App
