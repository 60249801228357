import React from 'react';
import {navigation, useStylesDrawerList} from "./index"
import {Stack, ListItemIcon, MenuItem, Modal, Typography, Button, Box} from "@mui/material"
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useAuth} from "../../auth/AuthProvider";
import {useModal} from "../../views/form/hooks";
import {BlueButton, ModalCard} from "../../index.style";
import {MENU_GRADIENT} from '../../constants/colors'
import {toast} from "react-toastify";


export const DrawerListMobile = ({handleClose}) => {
  const {t} = useTranslation();
  const location = useLocation()
  const {user, logout} = useAuth();
  const [isOpen, toggle] = useModal()
  const classes = useStylesDrawerList();

  const handler = (permissions) => {
    if(!permissions) {
      toast.error(t('have_access_section'), {autoClose: 2000, hideProgressBar: true})
      // handleClose()
    }
  }
  return(
    <Box sx={{ background: MENU_GRADIENT}}>
      {navigation(t).map((item, i) => {
          const action = location.pathname.includes(item.url)
        const permissions = user.permissions.includes(item.permission)

        return (
            item.url ?
              <NavLink key={i} to={permissions ? item.url : "#"} className={classes.drawerListLink} onClick={ ()=> handler(permissions)}>
                <MenuItem key={item} disabled={!permissions} selected={action} onClick={handleClose} className={classes.drawerListMenuItemMobile}>
                  <ListItemIcon className={classes.drawerListItemIconMobile}>
                    {item.icon}
                  </ListItemIcon>
                  {item.name}
                </MenuItem>
              </NavLink>
              :
              <MenuItem key={item} selected={action} onClick={toggle} className={classes.drawerListMenuItemMobileExit}>
                <ListItemIcon className={classes.drawerListItemIconMobile}>
                  {item.icon}
                </ListItemIcon>
                {item.name}
              </MenuItem>
          )
        })}

      <Modal open={isOpen} onClose={toggle}>
        <ModalCard className={classes.drawerListModal}>
          <Typography p={2} variant="h6">{t('are-you-get-out')}</Typography>
          <Stack p={2} direction="row" justifyContent="flex-end">
            <Button size="small" className={classes.drawerListModalButton} variant="contained" onClick={toggle}>{t('cancel')}</Button>
            <BlueButton size="small" variant="contained" onClick={logout}>{t('exit')}</BlueButton>
          </Stack>
        </ModalCard>
      </Modal>
    </Box>
  )
}
