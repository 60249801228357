import * as React from 'react';
import {makeStyles, styled} from "@mui/styles";
import {CircularProgress, Button, TextField, Box, Card, Modal, InputAdornment} from "@mui/material";

export const Hr = styled('hr')(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem',
  border: 0,
  borderTop: `1px solid ${theme.palette.background.hr}`,
}));
export const Loader = styled(CircularProgress)(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem',
  border: 0,
  borderTop: `1px solid ${theme.palette.background.hr}`,
}));
export const BlueButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.btnBlue,
  borderRadius: '4px !important',
  marginLeft: '0rem',
  marginRight: '1rem',
  "&:hover": {
    background: theme.palette.background.btnBlueHover
  },
}));
export const RedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.btnRed,
  borderRadius: '4px !important',
  marginLeft: '1rem',
  marginRight: '1rem',
  "&:hover": {
    background: theme.palette.background.btnRedHover
  },
}));
export const LoginTextField = styled(TextField)(({ theme }) => ({
  padding: '10px 10px 10px 0 !important',
  borderRadius: '0 5px 5px 0 !important',
  '& .MuiOutlinedInput-input': {
    borderRadius: '0 5px 5px 0 !important',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '10px !important',
  }
}));
export const MapWrapper = styled(Box)(({ theme }) => ({
  height: '80vh',
  minHeight: '300px',
  overflow: 'hidden',
}));

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
  overflow: 'auto'
}));
export const ModalCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '0',
  display: 'block',
  transform: 'translate(-50%, 3%)',
  maxWidth: '700px',
  minWidth: '600px',
  borderRadius: '5px',
  '@media screen and (max-width: 900px)': {
    maxWidth: '550px',
    minWidth: '450px'
  },
  '@media screen and (max-width: 800px)': {
    maxWidth: '550px',
    minWidth: '400px'
  },
  '@media screen and (max-width: 700px)': {
    maxWidth: '450px',
  },
  '@media screen and (max-width: 600px)': {
    maxWidth: '350px',
  },
  '@media screen and (max-width: 500px)': {
    maxWidth: '200px',
    minWidth: '300px'
  }
}));
export const ModalBody = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderTop: `1px solid ${theme.palette.background.inputBorder}`,
  borderBottom: `1px solid ${theme.palette.background.inputBorder}`,
}));

export const ReferenceTextField = styled(TextField)(({ theme }) => ({
  padding: '0px 0px 0px 0 !important',
  borderRadius: '5px 0 0 5px !important',

  '& .MuiOutlinedInput-input': {
    borderRadius: '5px 0 0 5px !important',
    borderBottom: `1px solid ${theme.palette.background.inputBorder}`,
    borderTop: `1px solid ${theme.palette.background.inputBorder}`,
    borderLeft: `1px solid ${theme.palette.background.inputBorder}`,
    borderRight: `0px solid ${theme.palette.background.inputBorder}`,

  },
  '& .MuiOutlinedInput-root': {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
    border: '0 !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
    border: '0 !important',
  }
}));

export const useStylesReferenceField = makeStyles(theme => ({
  iconButtonClear: {
    width: '30px',
    height: '30px',
    background: theme.palette.background.input,
    borderRadius: '0px !important',
    padding: '5px 5px 5px 5px !important',
    borderBottom: `1px solid ${theme.palette.background.inputBorder}`,
    borderTop: `1px solid ${theme.palette.background.inputBorder}`,
    borderLeft: `0px solid ${theme.palette.background.inputBorder}`,
    borderRight: `0px solid ${theme.palette.background.inputBorder}`,
    "&:hover": {
      background: theme.palette.background.inputHover,
    },
    '@media screen and (max-width: 900px)': {
      width: '25px',
      height: '24px',
    },
  },
  iconButtonSearch: {
    width: '30px',
    height: '30px',
    background: theme.palette.background.input,
    borderRadius: '0 5px 5px 0 !important',
    padding: '5px !important',
    borderBottom: `1px solid ${theme.palette.background.inputBorder}`,
    borderTop: `1px solid ${theme.palette.background.inputBorder}`,
    borderLeft: `0px solid ${theme.palette.background.inputBorder}`,
    borderRight: `1px solid ${theme.palette.background.inputBorder}`,
    "&:hover": {
      background: theme.palette.background.inputHover,
    },
    '@media screen and (max-width: 900px)': {
      width: '25px',
      height: '24px',
    },
  },
  icon: {
    fontSize: '18px',
    color: `${theme.palette.background.iconButton} !important`,
    padding: '0px !important'
  }
}))

export const useStylesInputFile = makeStyles(theme => ({
  inputFileWrapper: {
    width: '100%',
    height: '30px',
    position: 'relative',
    margin: '0',
    textAlign: 'center',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.background.inputBorder}`,
    borderRadius: '0.25rem !important'
  },
  inputFile: {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute'
  },
  inputFileIconWrapper: {
    height: '30px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #fff'
  },
  inputFileButtonText: {
    marginTop: '-2px',
    font: 'normal normal 15px/15px Roboto'
  },
  inputFileButton: {
    width: '100%',
    height: '30px',
    background: theme.palette.background.input,
    color: theme.palette.background.textColor,
    fontSize: '0.6rem',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '3px',
    cursor: 'pointer',
    margin: '0 auto'
  },
  addButton: {
    width: '',
    minWidth: '0',
    fontSize: '20px',
    padding: "0.3rem 0.5rem 0.4rem 0.5rem",
    margin: '0.1rem',
    lineHeight: '1',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.background.inputBorder}`,
    background: theme.palette.background.input,
    color: theme.palette.background.textColor,
    '@media screen and (max-width: 1600px)': {
      padding: "0.2rem 0.5rem 0.3rem 0.5rem",
    },
    '@media screen and (max-width: 900px)': {
      padding: "0.2rem 0.5rem 0.3rem 0.5rem",
      fontSize: '15px',
    },
    '@media screen and (max-width: 850px)': {
      padding: "0.2rem 0.5rem 0.3rem 0.5rem",
      fontSize: '15px',
    },
    '@media screen and (max-width: 600px)': {
      padding: "0.3rem 0.5rem 0.5rem 0.5rem",
      fontSize: '10px',
    }
  },

}))

export const useStylesEnumFilter = makeStyles(theme => ({
  enumFilterWrapper: {
    width: '100%',
    height: '20px !important'
  },
  enumFilterStack: {
    color: theme.palette.background.textColor,
    fontSize: '0.7rem !important'
  },
}))
export const useStylesLogin = makeStyles(theme => ({
  loginWrapIcon: {
    height: '0rem',
    background: theme.palette.background.loginIcon,
    padding: "14px 7px",
    marginRight: '0 !important',
    border: "1px solid #23282c",
    borderRight: "0px solid #23282c",
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    '@media screen and (max-width: 900px)': {
      padding: "11px 7px",
    }
  },
}))
