import React, {useState} from "react";
import {Card, Stack, Box, Typography, InputAdornment} from "@mui/material"
import {AccountCircle, VpnKey} from '@mui/icons-material';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useAuth} from "../../auth/AuthProvider";
import {useTranslation} from "react-i18next";
import {BlueButton, LoginTextField, useStylesLogin} from '../../index.style'
import {useTheme} from "@mui/material/styles";

const Login = () => {
  const { t } = useTranslation();
  const {login, } = useAuth()
  const loading = false
  const authSuccess = false
  const theme = useTheme();
  const classes = useStylesLogin()

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState("");

  const notify = (message = t('username-password-incorrectly')) => toast.error(message) && setError(message);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      login(username, password).catch(error => {
        if (error.response) {
          const status = error.response.status
          if (status >= 400 && status < 500) {
            notify()
          } else {
            notify(t('error-occurred'))
          }
        }
      })
    } else {
      notify(t('necessary-fill-all-fields'));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  }

  return (
      <Stack sx={{ height: '100vh', background: 'linear-gradient(to bottom, #00b7f4, #5f7377 70%, #4d4b4a)', overflow: 'auto' }} direction="row" justifyContent="center" alignItems="center">
        <Box>
          <Stack pb={5} direction="row" justifyContent="center" alignItems="center">
            <img src="/img/logo_white.svg" alt="Zevs_logo"/>
          </Stack>
          <Card sx={{padding: '2rem', maxWidth: '20rem'}}>
            <Typography variant="h4">{t('entrance')}</Typography>
            <Typography sx={{color: '#73818f'}} variant="subtitle2">{t('log-your-account')}</Typography>
            <LoginTextField
              sx={{maxHeight: '30px !important'}}
              id="outlined-basic"
              variant="outlined"
              onChange={event => setUsername(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.loginWrapIcon} position="start">
                    <AccountCircle sx={{fill: theme.palette.background.iconFill}} />
                  </InputAdornment>
                ),
              }}
            />
            <LoginTextField
              sx={{maxHeight: '30px !important'}}
              id="outlined-password"
              variant="outlined"
              type="password"
              onChange={event => setPassword(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.loginWrapIcon} position="start">
                    <VpnKey sx={{fill: theme.palette.background.iconFill}} />
                  </InputAdornment>
                ),
              }}
            />

            <BlueButton variant="contained" disabled={loading} onClick={handleLogin}>
              {loading && !authSuccess ? t('authorization') : t('enter')}
            </BlueButton>
          </Card>
        </Box>
      </Stack>
  )
}

export default Login
