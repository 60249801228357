export const MAIN_BG_WHITE = '#b3b3b3';
export const MAIN_BG_DARK = '#2f353a';
export const CARD_BG = '#3a4149';
export const CARD_BG_WHITE = '#F0F0F0';
export const CARD_BG_DARK = '#3a4149';
export const BUTTON_BG_HOVER_WHITE = '#a9b1b3';
export const BUTTON_BG_HOVER_DARK = '#626e7a';
export const BUTTON_BG_WHITE = '#b8bebf';
export const BUTTON_BG_DARK = '#73818F';
export const BUTTON_BORDER_WHITE = '#343536';
export const BUTTON_BORDER_DARK = '#5c6873';
export const TABLE_BORDER_WHITE = '#23282c';
export const TABLE_BORDER_DARK = '#23282c';
export const TABLE_HEADER_BG_WHITE = '#ffffff';
export const TABLE_HEADER_BG_DARK = '#343a40';
export const TABLE_BUTTON_BG_WHITE_DISABLED = '#a3a3a3 !important';
export const TABLE_BUTTON_BG_DARK_DISABLED = '#5D6975 !important';
export const TABLE_BUTTON_BG_WHITE = '#fff !important';
export const TABLE_BUTTON_BG_DARK = '#73818f !important';
export const TABLE_BUTTON_BG_HOVER_WHITE = '#c7c7c7 !important';
export const TABLE_BUTTON_BG_HOVER_DARK = '#626e7a !important';
export const TABLE_BUTTON_BG_HOVER_WHITE_RED = '#fa9f9f !important';
export const TABLE_BUTTON_BG_HOVER_DARK_RED = '#F86C6B !important';
export const TABLE_BUTTON_BORDER_WHITE = '#73818f !important';
export const TABLE_BUTTON_BORDER_DARK = 'transparent !important';
export const TABLE_BUTTON_BG_WHITE_RED = '#fff !important';
export const TABLE_BUTTON_BG_DARK_RED = '#F86C6B !important';
export const TABLE_BUTTON_BORDER_WHITE_RED = '#F86C6B !important';
export const TABLE_BUTTON_BORDER_DARK_RED = 'transparent !important';
export const TABLE_BUTTON_ICON_DEL = '#D14343 !important';
export const INPUT_BG_DARK = '#515B65';
export const INPUT_BG_WHITE = '#cacaca';
export const TAB_BG_DARK = '#343b41';
export const TAB_BG_WHITE = '#ffffff';
export const TOOLTIP_BG_DARK = '#2f353a';
export const TOOLTIP_BG_WHITE = '#ffffff';
export const INPUT_BORDER_DARK = '#23282c';
export const INPUT_BORDER_WHITE = '#5f7377';
export const INPUT_BORDER_ERROR = '#f86c6b';
export const LOGIN_ICON_BG_WHITE = '#9c9c9c';
export const LOGIN_ICON_BG_DARK = '#343B41';
export const BLUE = '#00b7f4';
export const GREY = '#5f7377 !important';
export const GREY_PAGINATION = '#3A4149 !important';
export const LIGHTS = '#f0f0f0';
export const DARK = '#3A4149';
export const DARK_GREY = '#4d4b4a';
export const RED = '#fd0303';
export const WHITE = '#e4e7ea';
export const WHITE_IMPORTANT = '#e4e7ea';
export const LIGHT = '#fff';
export const GREEN = '#56c5a1';
export const BLACK = '#323232';
export const TEXT_WHITE = '#e4e7ea !important';
export const TEXT_DARK = '#323232 !important';
export const LIGHT_GREY = '#cacaca';
export const MENU_GRADIENT = `linear-gradient(#00b7f4, #5f7377 130%, #6c6a67, #cacaca 200%) !important`;
export const GRADIENT = `linear-gradient(to bottom, ${BLUE}, ${GREY} 70%, ${DARK_GREY})`;
