import React, {createContext, useContext, useEffect, useState} from 'react'
import {useLocalStorage} from "../hooks/useLocalStorage";
import Login from "../components/Login";
import {useQueryClient} from "react-query";
import loginService from "../api/loginService";
import instance from "../api/config";
import {useTranslation} from "react-i18next";

const AuthContext = createContext({});

function AuthProvider({children}) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)
  const [accessToken, setAccessToken, clearAccessToken] = useLocalStorage('token_id', undefined)
  const queryClient = useQueryClient()

  const [options, setOptions] = useState([]) // options для Зарядные станции => Коннекторы => Тарифы

  useEffect(() => {
    if (accessToken) {
      setLoading(true)

      instance.get(`/employees/current`)
        .then(({data}) => {
          setUser(data)
          setLoading(false)
        }).catch((error)=>{
          logout()
          setLoading(false)
        })
    }
  }, [accessToken])

  const login = (email, password) => {
    return loginService.login(email, password)
      .then((payload) => {
        setAccessToken(payload.accessToken)
        return payload
      })
  }

  const logout = () => {
    setUser(undefined)
    clearAccessToken()
    queryClient.invalidateQueries()
  }

  const hasPermission = (permission) => user.permissions.includes(permission)

  const auth = {
    user,
    login,
    logout,
    hasPermission,

    options,
    setOptions
  }

  if (loading) {
    return <Loading/>
  }

  return <AuthContext.Provider value={auth}>{user ? children : <Login/>}</AuthContext.Provider>
}

function Loading() {
  const { t } = useTranslation();
  return <div className='appAuthLoader'>{t('authorization-loading')}</div>
}

const useAuth = () => useContext(AuthContext)


export {AuthProvider, useAuth}
