import {createTheme} from '@mui/material';
import {
  WHITE,
  RED,
  INPUT_BORDER_ERROR,
  INPUT_BORDER_DARK,
  BLACK,
  INPUT_BORDER_WHITE,
  INPUT_BG_DARK,
  INPUT_BG_WHITE,
  BUTTON_BG_DARK,
  BUTTON_BG_WHITE,
  BUTTON_BORDER_DARK,
  BUTTON_BORDER_WHITE,
  BUTTON_BG_HOVER_DARK,
  BUTTON_BG_HOVER_WHITE,
  CARD_BG_WHITE,
  CARD_BG_DARK,
  TABLE_HEADER_BG_WHITE,
  TABLE_HEADER_BG_DARK,
  TABLE_BORDER_WHITE,
  TABLE_BORDER_DARK,
  MAIN_BG_DARK,
  MAIN_BG_WHITE,
  TAB_BG_DARK,
  TAB_BG_WHITE,
  TOOLTIP_BG_WHITE,
  TOOLTIP_BG_DARK,
  TABLE_BUTTON_BG_DARK,
  TABLE_BUTTON_BG_WHITE,
  TABLE_BUTTON_BORDER_DARK,
  TABLE_BUTTON_BORDER_WHITE,
  TABLE_BUTTON_BG_HOVER_DARK,
  TABLE_BUTTON_BG_HOVER_WHITE,
  TABLE_BUTTON_BG_DARK_RED,
  TABLE_BUTTON_BG_WHITE_RED,
  TABLE_BUTTON_BORDER_DARK_RED,
  TABLE_BUTTON_BORDER_WHITE_RED,
  TABLE_BUTTON_BG_HOVER_DARK_RED,
  TABLE_BUTTON_BG_HOVER_WHITE_RED,
  TABLE_BUTTON_BG_DARK_DISABLED,
  TABLE_BUTTON_BG_WHITE_DISABLED,
  LIGHT,
  TABLE_BUTTON_ICON_DEL,
  TEXT_WHITE,
  TEXT_DARK,
  GREY,
  MENU_GRADIENT,
  LOGIN_ICON_BG_DARK,
  LOGIN_ICON_BG_WHITE, GREY_PAGINATION
} from './colors'

export function mainTheme(MUIMedia) {
  const {theme, max_width_900, media_max_width_2000} = MUIMedia

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1920
      }
    },
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: media_max_width_2000 ? '250px' : '200px',
            // width: '200px !important',
            background: MENU_GRADIENT,
            color: WHITE,
            height: '100vh',
            borderRight: '0px solid white'
          },
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            background: theme ? CARD_BG_DARK : CARD_BG_WHITE,
            color: theme ? WHITE : BLACK,
            borderRadius: 0,
            border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
            boxShadow: 'none',
            overflow: 'initial'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "::after": {
              display: 'none'
            },
            "::before": {
              display: 'none'
            },
          },
          input: {
            maxHeight: '14px !important'
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            width: '100%',
            borderRadius: '0.25rem',
            '& .Mui-error': {
              border: `1px solid ${INPUT_BORDER_ERROR}`
            }
          },
          input: {
            borderRadius: '0.25rem !important',
            background: theme ? INPUT_BG_DARK : INPUT_BG_WHITE,
            border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
            outline: 'none',
            color: theme ? WHITE : BLACK,
            padding: '5px 10px !important'
          }
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            borderRadius: '0.25rem !important',
          },
          select: {
            border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
            '@media screen and (max-width: 1200px)': {
            padding: "0.4rem 0.5rem 0.2rem 0.5rem !important",
          },
          '@media screen and (max-width: 845px)': {
            padding: "0.4rem 0.5rem 0.3rem 0.5rem !important",
          },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            width: '100%',
          },
          notchedOutline: {
            borderRadius: '0.25rem !important',
            border: 'none !important'
          },
          input: {
            height: max_width_900 ? '12px !important' : '18px !important',
            '&.Mui-disabled': {
              textFillColor: theme ? WHITE : BLACK,
            }
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: '#f86c6b',
            marginLeft: '0.5rem',
            marginTop: '0',
            fontSize: '0.7rem'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
            top: "-10px",
            "&.Mui-focused": {
              // height: '1rem',
              color: theme ? WHITE : BLACK,
              top: "-5px",
            },
            "&.MuiFormLabel-filled": {
              top: "0px",
            }
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
            textTransform: 'none',
            background: theme ? BUTTON_BG_DARK : BUTTON_BG_WHITE,
            borderColor: theme ? BUTTON_BORDER_DARK : BUTTON_BORDER_WHITE,
            "&:hover": {
              background: theme ? BUTTON_BG_HOVER_DARK : BUTTON_BG_HOVER_WHITE,
            },
          },
          sizeSmall: {
            borderRadius: '0.25rem'
          },
          sizeMedium: {
            padding: '0.4rem 0.7rem'
          },
          sizeLarge: {
            padding: '11px 24px'
          },
          textSizeSmall: {
            padding: '7px 12px'
          },
          textSizeMedium: {
            padding: '9px 16px'
          },
          textSizeLarge: {
            padding: '12px 16px'
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '0 !important',
            width: '15px',
            height: '15px',
            margin: '0.4rem'

          },
        }
      },
      MuiToggleButton: {
        variants: [
          {
            root: {
              color: theme ? WHITE : BLACK,
            },
            props: {variant: 'text'},
            style: {
              background: 'transparent',
              borderTop: 'none',
              borderLeft: 'none !important',
              borderRight: 'none',
              borderBottom: `2px solid ${theme ? CARD_BG_DARK : CARD_BG_WHITE}`,
              color: theme ? WHITE : BLACK,
              borderRadius: 0,
              fontSize: '0.7rem',
              lineHeight: '1.5',
              padding: "5px",
              marginRight: '5px',
              marginLeft: 0,

              "&:hover": {
                background: 'transparent',
                borderTop: 'none',
                borderLeft: 'none !important',
                borderRight: 'none',
                borderBottom: '2px solid #20a8d8',
                marginRight: '5px',
                marginLeft: 0,

              },
              "&.Mui-selected, &.Mui-selected:hover": {
                background: 'transparent',
                borderTop: 'none',
                borderLeft: 'none !important',
                borderRight: 'none',
                borderBottom: '2px solid #20a8d8',
                color: theme ? GREY : BLACK,

              }
            }
          }
        ],
        root: {
          selected: {
            backgroundColor: RED
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '32px 24px',
            '&:last-child': {
              paddingBottom: '32px'
            }
          }
        }
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h6'
          },
          subheaderTypographyProps: {
            variant: 'body2'
          }
        },
        styleOverrides: {
          root: {
            padding: '32px 24px'
          }
        }
      },
      // MuiCssBaseline: {
      //   root: {
      //     '*': {
      //       boxSizing: 'border-box',
      //       margin: 0,
      //       padding: 0
      //     },
      //     html: {
      //       MozOsxFontSmoothing: 'grayscale',
      //       WebkitFontSmoothing: 'antialiased',
      //       display: 'flex',
      //       flexDirection: 'column',
      //       minHeight: '100%',
      //       width: '100%',
      //       margin: '0 !important',
      //       padding: '0 !important'
      //     },
      //     body: {
      //       display: 'flex',
      //       flex: '1 1 auto',
      //       flexDirection: 'column',
      //       minHeight: '100%',
      //       width: '100%',
      //       margin: '0 !important',
      //       padding: '0 !important'
      //     },
      //     '#__next': {
      //       display: 'flex',
      //       flex: '1 1 auto',
      //       flexDirection: 'column',
      //       height: '100%',
      //       width: '100%',
      //       margin: '0 !important',
      //       padding: '0 !important'
      //     }
      //   }
      // },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: theme ? CARD_BG_DARK : CARD_BG_WHITE,
            color: theme ? WHITE : BLACK,
            fontSize: '12px',
            borderWidth: '0 0 0 0',
            borderColor: theme ? TABLE_BORDER_DARK : TABLE_BORDER_WHITE,
            borderStyle: 'solid',
            borderRadius: 0,
            boxShadow: 'none',
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
            padding: '0.3rem',
            fontSize: '12px',
            borderWidth: '0 0 0 1px',
            borderColor: theme ? TABLE_BORDER_DARK : TABLE_BORDER_WHITE,
            borderStyle: 'solid',
            whiteSpace: 'nowrap'
          },
          head: {
            backgroundColor: theme ? TABLE_HEADER_BG_DARK : TABLE_HEADER_BG_WHITE,
            lineHeight: '1.5rem',
            borderWidth: '1px 1px 0 1px',
            borderColor: theme ? TABLE_BORDER_DARK : TABLE_BORDER_WHITE,
            borderStyle: 'solid',
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            backgroundColor: theme ? CARD_BG_DARK : CARD_BG_WHITE,
            color: theme ? WHITE : BLACK,
            padding: '0.3rem',
            fontSize: '12px',
            lineHeight: '0.5rem',
            borderWidth: '0 1px 1px 0',
            borderColor: theme ? TABLE_BORDER_DARK : TABLE_BORDER_WHITE,
            borderStyle: 'solid'
          }
        }
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
            borderRadius: 0,
            "&.Mui-selected, &.Mui-selected:hover": {
              color: theme ? GREY_PAGINATION : WHITE,
              border: `1px solid ${theme ? GREY_PAGINATION : GREY_PAGINATION}`,
              backgroundColor: theme ? WHITE : BLACK,
            }
          },
          icon: {
            fill: theme ? WHITE : BLACK,
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: `${theme ? MAIN_BG_DARK : MAIN_BG_WHITE} !important`,
            borderRadius: '0.25rem !important',
          },
          list: {
            background: theme ? MAIN_BG_DARK : MAIN_BG_WHITE,
            borderRadius: '0.25rem',
            border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
            padding: '0px',

            "input[type='text']": {
              width: '20ch',
              background: theme ? INPUT_BG_DARK : INPUT_BG_WHITE,
              border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
              outline: 'none',
              color: theme ? WHITE : BLACK,
              padding: '5px',
            },
            "input[type='date']": {
              display: 'block',
              width: '20ch',
              background: theme ? INPUT_BG_DARK : INPUT_BG_WHITE,
              border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
              outline: 'none',
              borderRadius: '0.25rem !important',
              color: theme ? WHITE : BLACK,
              padding: '5px',
              indicator: {
                display: 'none',
              }
            },
            "input[type='checkbox']": {
              marginTop: '3px !important',
              width: 'auto'
            },
            "select": {
              background: theme ? INPUT_BG_DARK : INPUT_BG_WHITE,
              color: theme ? WHITE : BLACK,
              border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
              borderRadius: '0.25rem !important',
              padding: '3px',
              outline: 'none',
              transition: 'all .27s ease-in-out'
            },
            'select:focus': {
              outline: 'none',
            },
            "select:not([multiple])": {
              background: theme ? INPUT_BG_DARK : INPUT_BG_WHITE,
              color: theme ? WHITE : BLACK,
              padding: '3px'
            },
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0 !important',
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '6px !important',
            minHeight: '36px !important',
            color: theme ? WHITE : BLACK,
            "&.Mui-selected":{
              backgroundColor: '#10ACE0',
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: 'none',
          },
          flexContainer: {
            overflow: 'auto',
          },
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            font: '300 0.8rem/0.1rem',
            padding: '8px 12px',
            "&.Mui-selected": {
              // color: "#ea001b !important",
              color: theme ? WHITE : BLACK,
              backgroundColor: theme ? TAB_BG_DARK : TAB_BG_WHITE,
              border: `1px solid ${theme ? INPUT_BORDER_DARK : INPUT_BORDER_WHITE}`,
              borderTopLeftRadius: '0.25rem',
              borderTopRightRadius: '0.25rem',
              borderBottom: 'none'
            },
            "&.MuiTabs-indicator": {
              display: 'none'
            }
          },
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: theme ? WHITE : BLACK,
            width: '20px !important',
            height: '20px !important',

          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: '500px',
            background: theme ? TOOLTIP_BG_DARK : TOOLTIP_BG_WHITE,
            color: theme ? WHITE : BLACK,
          },
          arrow: {
            "::before": {
              background: '#2f353a'
            }

          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 10
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            maxHeight: '300px',
            overflow: 'auto !important'
          }
        }
      },
    },
    palette: {
      // type: 'light' || 'dark',
      neutral: {
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827'
      },
      action: {
        active: '#6B7280',
        focus: 'rgba(55, 65, 81, 0.12)',
        hover: 'rgba(55, 65, 81, 0.04)',
        selected: '#ccc',
        disabledBackground: 'rgba(55, 65, 81, 0.12)',
        disabled: 'rgba(55, 65, 81, 0.26)'
      },
      background: {
        main: theme ? MAIN_BG_DARK : MAIN_BG_WHITE,
        card: theme ? CARD_BG_DARK : CARD_BG_WHITE,
        default: '#F9FAFC',
        paper: '#FFFFFF',
        dark: '#3832A0',
        input: theme ? '#515B65' : "#CACACA",
        inputBorder: theme ? "#23282C" : '#5F7377',
        inputHover: '#626e7a',
        iconButton: theme ? WHITE : BLACK,
        tableButtonBg: theme ? TABLE_BUTTON_BG_DARK : TABLE_BUTTON_BG_WHITE,
        tableButtonBgRed: theme ? TABLE_BUTTON_BG_DARK_RED : TABLE_BUTTON_BG_WHITE_RED,
        tableButtonHoverBgRed: theme ? TABLE_BUTTON_BG_HOVER_DARK_RED : TABLE_BUTTON_BG_HOVER_WHITE_RED,
        tableButtonHoverBg: theme ? TABLE_BUTTON_BG_HOVER_DARK : TABLE_BUTTON_BG_HOVER_WHITE,
        tableButtonBorder: theme ? TABLE_BUTTON_BORDER_DARK : TABLE_BUTTON_BORDER_WHITE,
        tableButtonBorderRed: theme ? TABLE_BUTTON_BORDER_DARK_RED : TABLE_BUTTON_BORDER_WHITE_RED,
        tableButtonDisabled: theme ? TABLE_BUTTON_BG_DARK_DISABLED : TABLE_BUTTON_BG_WHITE_DISABLED,
        tableButtonIconDel: theme ? WHITE : TABLE_BUTTON_ICON_DEL,
        textColor: theme ? TEXT_WHITE : TEXT_DARK,
        chartTextColor: theme ? LIGHT : BLACK,
        iconFill: theme ? LIGHT : BLACK,
        loginIcon: theme ? LOGIN_ICON_BG_DARK : LOGIN_ICON_BG_WHITE,
        btnRedHover: '#f64846 !important',
        btnRed: '#f86c6b !important',
        btnBlue: '#20A8D8 !important',
        btnBlueHover: '#1b8eb7 !important',
        hr: 'rgba(0,0,0,.1)',
      },
      divider: '#E6E8F0',
      primary: {
        main: '#219FC8',
        light: '#828DF8',
        dark: '#3832A0',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#73818f',
        light: '#3FC79A',
        dark: '#0B815A',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#14B8A6',
        light: '#43C6B7',
        dark: '#0E8074',
        contrastText: '#FFFFFF'
      },
      info: {
        main: '#2196F3',
        light: '#64B6F7',
        dark: '#0B79D0',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FFB020',
        light: '#FFBF4C',
        dark: '#B27B16',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#D14343',
        light: '#DA6868',
        dark: '#922E2E',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#fff',
        secondary: '#65748B',
        disabled: 'rgba(55, 65, 81, 0.48)',
        textColorMenu: TEXT_WHITE,

      },
      colorUI: {
        inputHelperTextError: '#f86c6b'
      }
    },
    shape: {
      borderRadius: 8
    },
    shadows: [
      'none',
      '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
      '0px 1px 2px rgba(100, 116, 139, 0.12)',
      '0px 1px 4px rgba(100, 116, 139, 0.12)',
      '0px 1px 5px rgba(100, 116, 139, 0.12)',
      '0px 1px 6px rgba(100, 116, 139, 0.12)',
      '0px 2px 6px rgba(100, 116, 139, 0.12)',
      '0px 3px 6px rgba(100, 116, 139, 0.12)',
      '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
      '0px 5px 12px rgba(100, 116, 139, 0.12)',
      '0px 5px 14px rgba(100, 116, 139, 0.12)',
      '0px 5px 15px rgba(100, 116, 139, 0.12)',
      '0px 6px 15px rgba(100, 116, 139, 0.12)',
      '0px 7px 15px rgba(100, 116, 139, 0.12)',
      '0px 8px 15px rgba(100, 116, 139, 0.12)',
      '0px 9px 15px rgba(100, 116, 139, 0.12)',
      '0px 10px 15px rgba(100, 116, 139, 0.12)',
      '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
      '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)'
    ],
    typography: {
      button: {
        fontWeight: 600
      },
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      body1: {
        // color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 0.5rem)',
        fontWeight: 400,
        lineHeight: 1.5,
        // '@media screen and (min-width: 1000px)': {
        //   fontSize: '0.8rem'
        // },
        '@media screen and (min-width: 1600px)': {
          fontSize: '0.9rem'
        }
      },
      body2: {
        color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 0.8rem)',
        fontWeight: 400,
        lineHeight: 1.57,
        '@media screen and (min-width: 1600px)': {
          fontSize: '0.8rem'
        }
      },
      subtitle1: {
        color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 1rem)',
        fontWeight: 500,
        lineHeight: 1.75,
        '@media screen and (min-width: 1600px)': {
          fontSize: '1.2rem'
        }
      },
      subtitle2: {
        color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.1 + 0.7rem)',
        fontWeight: 400,
        lineHeight: 1.5,
        '@media screen and (min-width: 1600px)': {
          fontSize: '0.9rem'
        }
      },
      overline: {
        color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 0.6rem)',
        fontWeight: 700,
        letterSpacing: '0.5px',
        lineHeight: 1.5,
        textTransform: 'none !important',
        '@media screen and (min-width: 1600px)': {
          fontSize: '0.9rem'
        }
      },
      caption: {
        color: theme ? WHITE : BLACK,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 0.8rem)',
        fontWeight: 400,
        lineHeight: 1.5,
        '@media screen and (min-width: 1600px)': {
          fontSize: '1rem'
        }
      },
      h1: {
        color: theme ? WHITE : BLACK,
        fontWeight: 700,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 3.5rem)',
        lineHeight: 1.375,
        '@media screen and (min-width: 1600px)': {
          fontSize: '3.7rem'
        }
      },
      h2: {
        color: theme ? WHITE : BLACK,
        fontWeight: 700,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 3rem)',
        lineHeight: 1.375,
        '@media screen and (min-width: 1600px)': {
          fontSize: '3.2rem'
        }
      },
      h3: {
        color: theme ? WHITE : BLACK,
        fontWeight: 700,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 2.5rem)',
        lineHeight: 1.375,
        '@media screen and (min-width: 1600px)': {
          fontSize: '2.7rem'
        }
      },
      h4: {
        color: theme ? WHITE : BLACK,
        fontWeight: 700,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.2 + 2rem)',
        lineHeight: 1.375,
        '@media screen and (min-width: 1600px)': {
          fontSize: '2.2rem'
        }
      },
      h5: {
        color: theme ? WHITE : BLACK,
        fontWeight: 600,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.1 + 1.1rem)',
        lineHeight: 1.2,
        '@media screen and (min-width: 1600px)': {
          fontSize: '1.4rem'
        }
      },
      h6: {
        color: theme ? WHITE : BLACK,
        fontWeight: 600,
        fontSize: 'calc( (100vw - 30rem)/50 * 0.1 + 1rem)',
        lineHeight: 1.375,
        '@media screen and (min-width: 1600px)': {
          fontSize: '1.3rem'
        }
      }
    }
  })
}
