import {navigation, useStylesDrawerList, WrapperButton} from "./index";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  IconButton,
  Modal,
  Typography,
  Stack,
  Button
} from "@mui/material";
import {ChevronLeft, ChevronRight, LogoutOutlined} from "@mui/icons-material";
import * as React from "react";
import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {useAuth} from "../../auth/AuthProvider";
import {useLocation} from 'react-router-dom';
import {ModalCard, BlueButton} from "../../index.style";
import {useModal} from "../../views/form/hooks";
import {toast} from "react-toastify";


export const DrawerList = ({open, setOpen, language}) => {
  const {t} = useTranslation();
  const {user, logout} = useAuth();
  const location = useLocation()
  const [isOpen, toggle] = useModal()
  const classes = useStylesDrawerList({open});
  const navConfig = useMemo(() => {
    return {
      items: navigation(t).filter(item => {
        return item.permission ? true : true
        // return item.permission ? user.permissions.includes(item.permission) : true
      })
    }
  }, [user.permissions, language, t])

  const handler = (permissions) => {
    if(!permissions) toast.error(t('have_access_section'), {autoClose: 2000, hideProgressBar: true})
  }

  return (
    <>
      <List className={classes.drawerList}>
        {navConfig.items.map((item, i) => {
          let action
          if(location.pathname === '/' && item.url === '/dashboard'){
            action = true
          } else {
            action = location.pathname.includes(item.url)
          }
          const permissions = user.permissions.includes(item.permission)
          return (
              <NavLink key={i} to={permissions ? item.url : "#"} className={classes.drawerListLink} onClick={ ()=> handler(permissions)}>
                <ListItemButton selected={action} disabled={!permissions} key={i} className={classes.drawerListItemButton}>
                  <ListItemIcon className={classes.drawerListItemIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} className={classes.drawerListItemText}/>
                </ListItemButton>
              </NavLink>
          )
        })}

        <div className={classes.drawerListLink} onClick={toggle}>
          <ListItemButton className={classes.drawerListItemButton}>
            <ListItemIcon className={classes.drawerListItemIcon}>
              <LogoutOutlined sx={{fill: 'white'}}/>
            </ListItemIcon>
            <ListItemText primary={t('exit')} className={classes.drawerListItemText}/>
          </ListItemButton>
        </div>
      </List>
      <WrapperButton>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ChevronLeft className={classes.drawerListChevron}/> :
            <ChevronRight className={classes.drawerListChevron}/>}
        </IconButton>
      </WrapperButton>

      <Modal open={isOpen} onClose={toggle}>
        <ModalCard sx={{top: '30% !important'}} className={classes.drawerListModal}>
          <Typography p={2} variant="h6">{t('are-you-get-out')}</Typography>
          <Stack p={2} direction="row" justifyContent="flex-end">
            <Button size="small" className={classes.drawerListModalButton} variant="contained" onClick={toggle}>
              {t('cancel')}
            </Button>
            <BlueButton size="small" variant="contained" onClick={logout}>{t('exit')}</BlueButton>
          </Stack>
        </ModalCard>
      </Modal>

    </>
  )
}
