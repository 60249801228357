import React, {useState} from 'react';
import {DrawerHeaders, Drawer, DrawerList, DrawerListMobile, useStylesDrawer, MenuContainerMobile} from "./index"
import {Stack, IconButton, useMediaQuery, Menu, Box} from "@mui/material"
import {Menu as MenuIcon } from '@mui/icons-material';
import {ZevsLogo, RusGidroLogoMobile} from '../../image/index'
import {useAuth} from "../../auth/AuthProvider";

export function MuiDrawer({children, language}) {
  const [open, setOpen] = useState(true)
  const min_width_900 = useMediaQuery('(min-width:900px)')
  const classes = useStylesDrawer({min_width_900: min_width_900});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMobile = Boolean(anchorEl);
  const {user} = useAuth()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.drawerWrapper}>
      {min_width_900 ?
        <>
          <Drawer variant="permanent" open={open}>
            <DrawerHeaders open={open} setOpen={setOpen}/>
            <DrawerList open={open} setOpen={setOpen} language={language}/>
          </Drawer>
          <Stack p={1} sx={{width: '100%', overflow: 'auto'}}>
            {children}
          </Stack>
        </>:
        <>
          <MenuContainerMobile>
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMobile ? 'long-menu' : undefined}
                aria-expanded={openMobile ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.drawerIconButtonMobile}
              >
                <MenuIcon className={classes.drawerMenuIconButtonMobile} />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{'aria-labelledby': 'long-button'}}
                anchorEl={anchorEl}
                open={openMobile}
                onClose={handleClose}
              >
                <DrawerListMobile handleClose={handleClose}/>
              </Menu>
            </Box>
            <Box m={1} mt={1.1} mb={0.5}>
              {user.role === 'RUSHYDRO_OPERATOR' ?
                <img src={RusGidroLogoMobile} className={classes.drawerMenuIconLogoMobile}  alt="logo"/>
                :
                <img src={ZevsLogo} alt="logo"/>
              }
            </Box>


          </MenuContainerMobile>
          <Stack p={1} sx={{width: '-webkit-fill-available', overflow: 'auto'}}>
            {children}
          </Stack>
        </>}
    </Box>
  );
}
